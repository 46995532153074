.contenedor-imgprincipal-AS {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../../assets/img/ImgSoluciones/asesoriasimg1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2200px;
  height: 440px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Quicksand;
}
.contendor-titulo-AS {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 2;
}
.contenedor-sub-AS {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  margin-bottom: "80px";
}
.contenedor-titulo-AS {
  grid-column: 1/-1;
  grid-row: 4;
  height: 7vh;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
}
.contenedor-titulo-AS2 {
  width: 70%;
  color: #282828;
  font-size: 36px;
}
.titulo-tec-AS {
  width: 70%;
  color: #282828;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
}
.orden-imagecard-AS {
  grid-column: 1/-1;
  grid-row: 8;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.boton-programarreunion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contenedor-tit-boton {
  grid-column: 1/-1;
  grid-row: 9;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-imgprincipal-AS {
    grid-column: 1/-1;
    grid-row: 2;
    background-image: url("../../assets/img/ImgSoluciones/asesoriasimg1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1300px;
    height: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: Quicksand;
  }
  .contendor-titulo-AS {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 1.3;
  }
  .contenedor-sub-AS {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    width: 80%;
    text-align: center;
    margin-top: 30px;
  }
  .contenedor-titulo-AS {
    grid-column: 1/-1;
    grid-row: 4;
    height: auto !important;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Quicksand;
  }
  .contenedor-titulo-AS2 {
    width: 90%;
    color: #282828;
    font-size: 36px;
  }

  .titulo-tec-AS {
    width: 90%;
    color: #282828;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
  }
  .orden-imagecard-AS {
    grid-column: 1/-1;
    grid-row: 8;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  .boton-programarreunion {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .contenedor-tit-boton {
    grid-column: 1/-1;
    grid-row: 9;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
  }
}
