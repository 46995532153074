.contenedor-titulo-tecnologia {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.9;
  color: #fff;
  font-family: Quicksand;
}
.contenedor-sub-tecnologia {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  font-family: Quicksand;
}
.containTitTextImg {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-column: 1/-1;
  grid-row: 3;
  height: 50vh;
}
.containTitYtext {
  flex-direction: column;
  width: 35%;
  font-family: Quicksand;
  padding: 20px;
}
.Tittulo {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #282828;
}
.ttext {
  margin-top: 10px;
  font-size: 15px;
  color: #494949;
}

.containImg {
  width: 35%;
  height: 400px;
}
.estiloImagen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 600px;
  height: 380px;
  margin-top: 10px;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-titulo-tecnologia {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 33px;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
    font-family: Quicksand;
    width: 90%;
  }
  .contenedor-sub-tecnologia {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    width: 90%;
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
    font-family: Quicksand;
    padding-top: 20px;
  }
  .containTitTextImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-column: 1/-1;
    grid-row: 3;
    height: auto;
  }
  .containTitYtext {
    padding-top: 40px;

    flex-direction: column;
    width: 95%;
    font-family: Quicksand;
    /* background-color: aqua; */
  }
  .ttext {
    margin-top: 15px;
    font-size: 15px;
    color: #494949;
    line-height: 1.5;
    margin-left: 15px;
    width: 90%;
    padding-bottom: 10px;
  }
  .containImg {
    width: 95%;
    height: auto;
  }
  .estiloImagen {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 370px;
    height: 300px;
  }
}
