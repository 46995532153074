.contenedor1-textoimg-puerto {
  grid-column: 3/-1;
  grid-row: 4;
  height: auto;
  width: 100%;
  padding-top: 30px;
}
.contenedor-textoimg-puerto {
  font-family: Quicksand;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  width: 70%;
}
.contenedor-img1-puerto {
  background-image: url("../../assets/img/ImgNovedades/puertos.png");
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: 900px;
  height: 350px;
  display: flex;
}
.titulo-puerto {
  padding-top: 20px;
  font-size: 26px;
  font-weight: bold;
  width: 90%;
  height: auto;
}
.subtitulo-puerto {
  font-size: 24px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}
.imagen-puerto1 {
  background-image: url("../../assets/img/ImgPuerto/puerto1.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.texto-puerto {
  font-size: 14px;
  padding-top: 10px;
  line-height: 1.5;
  color: #494949;
  width: 80%;
  font-weight: 400;
}
.imagen-puerto2 {
  background-image: url("../../assets/img/ImgPuerto/puerto2.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto3 {
  background-image: url("../../assets/img/ImgPuerto/puerto3.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto4 {
  background-image: url("../../assets/img/ImgPuerto/puerto4.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 130px;
  display: flex;
}
.imagen-puerto5 {
  background-image: url("../../assets/img/ImgPuerto/puerto5.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto6 {
  background-image: url("../../assets/img/ImgPuerto/puerto6.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto7 {
  background-image: url("../../assets/img/ImgPuerto/puerto7.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto8 {
  background-image: url("../../assets/img/ImgPuerto/puerto8.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto9 {
  background-image: url("../../assets/img/ImgPuerto/puerto9.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
.imagen-puerto10 {
  background-image: url("../../assets/img/ImgPuerto/puerto10.jpg");
  background-repeat: no-repeat;
  background-size: 300px;
  height: 200px;
  display: flex;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor1-textoimg-puerto {
    grid-column: 2/-1;
    grid-row: 4;
    height: auto;
    width: 100%;
    padding-top: 30px;
  }
  .contenedor-textoimg-puerto {
    font-family: Quicksand;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
