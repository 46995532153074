.contenedor-imgprincipal-CE {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../../assets/img/ImgSoluciones/controlimg1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  height: 440px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Quicksand;
}
.contendor-titulo-CE {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 2;
}
.contenedor-sub-CE {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-imgprincipal-CE {
    grid-column: 1/-1;
    grid-row: 2;
    background-image: url("../../assets/img/ImgSoluciones/controlimg1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1300px;
    height: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: Quicksand;
  }
  .contendor-titulo-CE {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 1.3;
  }
  .contenedor-sub-CE {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    width: 80%;
    text-align: center;
    margin-top: 30px;
  }
}
