/* estilos.css */

.estiloOpciones {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 100px;
  font-weight: 600;
  color: #105186;
  font-size: 18px;
  margin-left: 31px;
}
.estiloOpciones:hover {
  color: #7398b8;
}
.border.activeBorder {
  border-bottom: 1px solid #105186;
}
.Ingresar {
  display: flex;
  flex-direction: row;
  border: 2px solid #105186;
  border-radius: 15px;
  padding: 2px 20px 2px 20px;
  color: #105186;
}
.Ingresar:hover {
  border: 2px solid #7398b8;
  color: #7398b8;
}
.colorIco {
  fill: #105186;
  transition: fill 0.3s;
}
.colorIco:hover {
  fill: #7398b8;
}

/* menu soluciones y Dispositivos */
.estiloOpciones2 {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 100px;
  font-weight: bold;
  color: #105186;
  cursor: "pointer ";
  grid-column: "6";
  grid-row: 1;
  z-index: 999;
}
.subrayed {
  text-decoration: underline;
}
/*  .................... */

/****************** menuitem */

.bold {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  height: 35px;
  padding-left: 7px;
  padding-right: 7px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Ajusta la opacidad según lo desees */
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; /* Evita que el overlay sea interactivo durante la animación */
}

.overlay.active {
  opacity: 1;
  pointer-events: auto;
}
