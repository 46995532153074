/* Definición de la animación de difuminado */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Desplazamiento hacia arriba */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
