.contenedorContacto1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: Quicksand;
}
.contenedorTexto1 {
  width: 633px;
  height: 30%;
  margin-bottom: 50px;
  padding-right: 20px;
}
.tituloCOM1 {
  font-size: 23px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: #548fff;
}
.contenedorForm1 {
  width: 643px;
  height: 90%;
  text-align: center;
}
.contendorLabelInput1 {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
label {
  color: #494949;
  margin-bottom: 5px;
  font-size: 16px;
}
input {
  height: 47px;
  width: 633px;
  border: 1px solid #acb6bf;
}
.contendorLabelTextarea1 {
  display: flex;
  flex-direction: column;
}
textarea {
  min-height: 130px;
  border: 1px solid #acb6bf;
}
.contenedorButton1 {
  height: 50px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #494949; */
}
.boton1 {
  background-color: #f7921e;
  border: none;
  color: #fff;
  height: 46px;
  width: 96px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.boton1:hover {
  background-color: #fff;
  border: 1px solid #f7921e;
  color: #f7921e;
}

/* //************************ESTILOS ERRORES */
.contendorLabelInput1 {
  margin-bottom: 10px;
}

.inputField1 {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.inputField1.touched {
  border-color: #ff8a04;
}

.error1 {
  color: #ff8800;
  font-size: 12px;
  margin-top: 5px;
}
/* ..............................envio de formulario */
.success-message1 {
  color: #ff8800;
  margin-left: 10px;
  font-weight: 600;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .tituloCOM1 {
    font-size: 23px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    color: #000000;
  }
  .contenedorTexto1 {
    width: 85%;
    height: 30%;
    margin-bottom: 50px;
    padding-right: 20px;
  }
  .contenedorForm1 {
    width: 80%;
    /* width: 33.7%; */
    height: 90%;
    /* background-color: blueviolet; */
    text-align: center;
  }
  .contendorLabelInput1 {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    /* background-color: blueviolet; */
  }
  label {
    color: #494949;
    margin-bottom: 5px;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
  }
  input {
    height: 47px;
    width: 98%;
    border: 1px solid #acb6bf;
  }
  /* //************************ESTILOS ERRORES */

  .inputField1 {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
  }

  .inputField1.touched {
    border-color: #ff8a04;
  }

  .error1 {
    color: #ff8800;
    font-size: 12px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
  }
  .boton1 {
    background-color: #0c2de6;
    border: none;
    color: #fff;
    height: 46px;
    width: 300px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  .boton1:hover {
    background-color: #fff;
    border: 1px solid #0c2de6;
    color: #0c2de6;
  }
}
