.contenedorContacto {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: #475c97; */
  align-items: center;
  width: 100%;
  height: 600px;
  font-family: Quicksand;
}
.contenedorTexto {
  width: 633px;
  height: 30%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  /* background-color: #b8cbff; */
}
.tituloCOM {
  font-size: 26px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-weight: bold;
  color: #282828;
}
.textoCOM {
  font-size: 14px;
  color: #494949;
  width: 100%;
  font-weight: 400;
}
.contenedorForm {
  width: 643px;
  margin-top: 20px;
  height: 90%;
  /* background-color: blueviolet; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contendorLabelInput {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
label {
  color: #494949;
  margin-bottom: 5px;
  font-size: 16px;
}
input {
  height: 47px;
  width: 633px;
  border: 1px solid #acb6bf;
}
.contendorLabelTextarea {
  display: flex;
  flex-direction: column;
}
textarea {
  /* height: auto; */
  min-height: 130px;
  border: 1px solid #acb6bf;
}
.contenedorButton {
  height: 50px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: #494949; */
}
.boton {
  background-color: #174ee2;
  border: none;
  color: #fff;
  height: 46px;
  width: 96px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.boton:hover {
  background-color: #fff;
  border: 1px solid #174ee2; /* Agregamos un borde azul */
  color: #174ee2;
}

/* //************************ESTILOS ERRORES */
.contendorLabelInput {
  margin-bottom: 10px;
}

.inputField {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.inputField.touched {
  border-color: #ff8a04;
}

.error {
  color: #ff8800;
  font-size: 12px;
  margin-top: 5px;
}
/* ..............................envio de formulario */
.success-message {
  color: #ff8800;
  margin-left: 10px;
  font-weight: 600;
}
