/* @media screen and (min-width: 768px) { */
/* //todo ........................... SECCION TARJETAS INICIO......... */
/* Estilo para el contenedor interno */
.contenedor-imgprincipal-Inicio {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../assets/img/ImgHome/InicioOficina.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  height: 400px;
  display: flex;
  justify-content: center;
  font-family: Quicksand;
}
.contenedor-interno {
  height: 580px;
  width: 301px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

/* Estilo para la imagen de fondo */
.imagen-fondo {
  width: 100%;
  height: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.texto0 {
  font-family: Quicksand;
}
/* Estilo para el título */
.titulo {
  width: 97%;
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 3%;
  font-size: 18px;
  font-weight: bold;
  color: #282828;
}

/* Estilo para el texto */
.texto {
  width: 93%;
  height: 75px;
  padding-left: 3%;
  font-size: 14px;
  color: #747474;
}

/* Estilo para el botón "Leer más" */
.boton-leer-mas {
  margin-left: 3%;
  width: 102px;
  height: 47px;
  border-radius: 7px;
  background-color: #f4524d;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilo para el botón "Leer más" en hover */
.boton-leer-mas:hover {
  background-color: #f23630;
}

.ContenedorTrespartes {
  grid-column: 1/-1;
  grid-row: 4;
  height: auto;
  width: 100%;
}

/* Estilos base para el contenedor */
.ContenedorTrespartes1 {
  height: 45vh;
  display: grid;
  overflow-x: hidden;
  width: 100%;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: auto;
  font-family: Quicksand;
}
.contenedorparte1de3 {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contenedorparte2de3 {
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contenedorparte3de3 {
  grid-column: 4;
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contenedor-imageninicio {

}
.contenedorIso {
  grid-column: 1/-1;
  grid-row: 6;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.claseIso {
  background-image: url("../assets/img/ImgHome/cert-ISO.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 8vh;
  width: 70%;
}
.lineaInicioH {
  grid-column: 3/11;
  grid-row: 7;
  background-color: #e9e9ff;
  width: 100%;
  height: 1px;
}

.Separador {
  grid-column: 1/7;
  grid-row: 8;
  height: 5vh;
}
.ContenedorPrimerTextInicio {
  grid-column: 1/7;
  grid-row: 9;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.PrimerTextInicio {
  width: 70%;
  display: flex;
  font-weight: bold;
  font-size: 32px;
  font-family: Quicksand;
  line-height: 1.7;
}
.PrimerTextoInicio1 {
  margin-top: 10px;
  width: 70%;
  display: flex;
  font-size: 24px;
  color: #515151;
  font-family: Quicksand;
  line-height: 1.5;
}
.contenedorVideo1 {
  grid-column: 7/-1;
  grid-row: 9;
  background-color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.LineaVideo {
  grid-column: 2/7;
  grid-row: 11;
  background-color: #e9e9ff;
  width: 100%;
  height: 1px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.contenedorVideo2 {
  grid-column: 1/7;
  grid-row: 13;
  background-color: #000;
  height: 60vh;
  display: flex;
  align-items: center;
  width: 100%;
}
.ContenedorTexto2 {
  grid-column: 7/-1;
  grid-row: 13;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SegundoTextoTitulo {
  width: 70%;
  display: flex;
  font-weight: bold;
  font-size: 32px;
  font-family: Quicksand;
  line-height: 1.7;
}
.SegundoTextoInicio {
  margin-top: 10px;
  width: 70%;
  font-size: 24px;
  color: #515151;
  font-family: Quicksand;
  line-height: 1.5;
}
.separador-10VH-5VH {
  grid-column: 1/-1;
  grid-row: 15;
  height: 10vh;
}
.contenedor-3secciones {
  grid-column: 1/-1;
  grid-row: 16;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contenedor-secciones {
  width: 30%;
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.imagen-redondaE {
  border-radius: 150px;
  background-image: url("../assets/img/ImgHome/elias.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-top: 20px;
}

.contenedor-peñaflor2 {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imagen-redonda {
  border-radius: 150px;
  background-image: url("../assets/img/ImgHome/peñaflor.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;  margin-top: 20px;

}
.contenedor-text-peñaflor {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #494949;
  font-size: 15px;
  line-height: 2;
  margin-top: 20px;
 
}
.nombres {
  height: auto;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin-top: auto;
}
.imagen-redondaP {
  border-radius: 150px;
  background-image: url("../assets/img/ImgHome/pro-logit.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-top: 20px;
}
.confianEnNosotros {
  grid-column: 1/-1;
  grid-row: 21;
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #282828;
}
.slide-imagenes {
  grid-column: 1/-1;
  grid-row: 23;
}
.separador-10VH {
  grid-column: 1/-1;
  grid-row: 24;
  height: 10vh;
}
.contenedor-tarjetas {
  grid-column: 1/-1;
  grid-row: 25;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.separardor-70-10 {
  grid-column: 1/-1;
  grid-row: 22;
  height: 70px;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-imgprincipal-Inicio {
    grid-column: 1/-1;
    grid-row: 2;
    background-image: url("../assets/img/ImgHome/InicioOficina.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2000px;
    height: 600px;
    display: flex;
    justify-content: center;
    font-family: Quicksand;
  }
  .ContenedorTrespartes {
    grid-column: 1/-1;
    grid-row: 4;
    height: auto;
    width: 100%;
  }
  .ContenedorTrespartes1 {
    height: auto;
    display: grid;
    overflow-x: hidden;
    width: 100%;
    grid-template-rows: auto;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .contenedorparte1de3 {
    grid-row: 1;
    grid-column: 1/-1;
    height: auto;
    margin-bottom: 10px;
  }
  .contenedorparte2de3 {
    grid-row: 2;
    grid-column: 1/-1;
    height: auto;
    margin-bottom: 20px;
  }
  .contenedorparte3de3 {
    height: auto;
    grid-row: 3;
    grid-column: 1/-1;
  }
  .contenedor-imageninicio {
    background-image: url("../assets/img/ImgHome/num3.png");
    width: 100%;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
  }

  .claseIso {
    background-image: url("../assets/img/ImgHome/iramInicio.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 13vh;
    width: 90%;
  }
  .Separador {
    grid-column: 1/-1;
    grid-row: 8;
    height: 10vh;
  }
  .ContenedorPrimerTextInicio {
    grid-column: 1/-1;
    grid-row: 9;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PrimerTextInicio {
    width: 85%;
    font-weight: bold;
    font-size: 32px;
    font-family: Quicksand;
  }
  .PrimerTextoInicio1 {
    margin-top: 10px;
    width: 85%;
    display: flex;
    font-size: 24px;
    color: #515151;
    font-family: Quicksand;
    line-height: 1.5;
  }
  .contenedorVideo1 {
    grid-column: 1/-1;
    grid-row: 10;
    background-color: #000;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    height: auto;
  }
  .LineaVideo {
    grid-column: 2/12;
    grid-row: 11;
    background-color: #e9e9ff;
    width: 100%;
    height: 0px;
    margin-top: 15px;
  }
  .contenedorVideo2 {
    grid-column: 1/-1;
    grid-row: 12;
    background-color: #000;
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .ContenedorTexto2 {
    grid-column: 1/-1;
    grid-row: 13;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .SegundoTextoTitulo {
    width: 90%;
    align-items: center;
    font-weight: bold;
    font-size: 30px;
    font-family: Quicksand;
    margin-top: 20px;
  }
  .SegundoTextoInicio {
    margin-top: 10px;
    width: 90%;
    font-size: 24px;
    color: #515151;
    font-family: Quicksand;
    line-height: 1.5;
  }
  .separador-10VH-5VH {
    grid-column: 1/-1;
    grid-row: 15;
    height: 5vh;
  }
  .contenedor-3secciones {
    grid-column: 1/-1;
    grid-row: 16;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contenedor-secciones {
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 20px;
  }
  .imagen-redonda {
    border-radius: 150px;
    background-image: url("../assets/img/ImgHome/peñaflor.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 109px;
    width: 103px;
  }
  .imagen-redondaE {
    border-radius: 150px;
    background-image: url("../assets/img/ImgHome/elias.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 109px;
    width: 103px;
  }
  .contenedor-text-peñaflor {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #494949;
    font-size: 15px;
    line-height: 2;
    padding-bottom: 15px;
  }
  .nombres {
    height: auto;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }
  .contenedor-prologit2 {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }
  .imagen-redondaP {
    border-radius: 150px;
    background-image: url("../assets/img/ImgHome/pro-logit.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 109px;
    width: 103px;
  }
  .nombres {
    height: 50px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }
  .confianEnNosotros {
    grid-column: 1/-1;
    grid-row: 21;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #282828;
  }
  .contenedor-tarjetas {
    grid-column: 1/-1;
    grid-row: 25;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .separardor-70-10 {
    grid-column: 1/-1;
    grid-row: 22;
    height: 10px;
  }
}
