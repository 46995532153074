.contenedor-cuadros-soluciones {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.clase-cuadro-soluciones {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  background-image: url("../assets/img/ImgSoluciones/MonitoreoAduanero.jpeg");
  height: 250px;
  width: 250px;
  cursor: pointer;
}
.clase-cuadro-soluciones:hover {
  box-shadow: "rgba(0, 0, 0, 0.25) 5px 5px 5px, rgba(0, 0, 0, 0.22) 5px 5px 5px";
}
.clase-cuadro-soluciones2 {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  background-image: url("../assets/img/ImgSoluciones/MonitoreoActivo.jpeg");
  height: 250px;
  width: 250px;
  cursor: pointer;
}
.clase-cuadro-soluciones3 {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  background-image: url("../assets/img/ImgSoluciones/ControldeEscoltas.jpeg");
  height: 250px;
  width: 250px;
  cursor: pointer;
}
.clase-cuadro-soluciones4 {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  background-image: url("../assets/img/ImgSoluciones/Asesorias.png");
  height: 250px;
  width: 250px;
  cursor: pointer;
}
.separador-cuadros-soluciones {
  width: 80px;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */

@media (max-width: 950px) {
  .contenedor-cuadros-soluciones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .clase-cuadro-soluciones {
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 250px;
    background-image: url("../assets/img/ImgSoluciones/MonitoreoAduanero.jpeg");
    height: 250px;
    width: 250px;
    cursor: pointer;
  }
  .separador-cuadros-soluciones {
    height: 20px;
  }
}
