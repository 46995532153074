.image-container {
  position: relative;
  display: inline-block;
}

.image {
  transition: transform 0.7s ease;
}

.text-overlay {
  font-family: Quicksand;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 14px;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) rotateY(180deg); /* Inicialmente invertido */
  transition: opacity 0.2s, visibility 0.1s, transform 0.7s ease;
}
.text-overlay2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 16px;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) rotateY(180deg); /* Inicialmente invertido */
  transition: opacity 0.2s, visibility 0.1s, transform 0.7s ease;
}

.text-overlay2 {
  height: 260px;
  width: 260px;
  background-color: rgb(233, 233, 233);
  color: #000;
  border-radius: 19px;
}

.image-container:hover .image {
  transform: rotateY(180deg);
}

.image-container:hover .text-overlay,
.image-container:hover .text-overlay2 {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%) rotateY(0deg); /* Enderezar el texto */
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 300px;
  }
  .image {
    transition: transform 0.7s ease;
  }
}
