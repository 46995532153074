.contenedor-imagenprincipal-Empresa {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../assets/img/ImgEmpresa/empresaimg1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Quicksand;
}
.separador-empresa {
  grid-column: 1/-1;
  grid-row: 3;
  height: 2vh;
}
.contenedor-subtitulo-Empresa {
  grid-column: 1/-1;
  grid-row: 5;
  text-align: center;
  height: 10vh;
  font-weight: bold;
  font-size: 24px;
  color: #282828;
  font-family: Quicksand;
}
.texto-antecedentes-Empresa {
  grid-column: 3/7;
  grid-row: 6;
  height: auto;
  font-family: Quicksand;
  font-size: 17px;
  line-height: 1.5;
}
.america-colsa-empresa {
  grid-column: 7/11;
  grid-row: 6;
  background-image: url("../assets/img/ImgEmpresa/americaColsa.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 500px;
  height: 80vh;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-subtitulo-Empresa {
    grid-column: 1/-1;
    grid-row: 5;
    text-align: center;
    height: 10vh;
    font-weight: bold;
    font-size: 18px;
    color: #282828;
    font-family: Quicksand;
  }
  .texto-antecedentes-Empresa {
    margin-top: 20px;
    grid-column: 2/12;
    grid-row: 6;
    height: auto;
    font-family: Quicksand;
    font-size: 17px;
  }
  .america-colsa-empresa {
    grid-column: 2/12;
    grid-row: 7;
    background-image: url("../assets/img/ImgEmpresa/americaColsa.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 500px;
    height: 90vh;
  }
}
