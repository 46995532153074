.contenedor-imgprincipal-MAc {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../../assets/img/ImgSoluciones/MADimg1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  height: 440px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Quicksand;
}
.contendor-titulo-MAc {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 2;
}

.contenedor-imagecard-MAc {
  grid-column: 1/-1;
  grid-row: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
  margin-top: 50px;
}
.contenedor-sub-MAc {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.contenedor-tit-MAc {
  grid-column: 1/-1;
  grid-row: 4;
  height: 7vh;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
}
.contendor-texto-MAc {
  font-size: 14px;
  width: 70%;
  line-height: 1.3;
}
.contendor-teximagenes-MAc {
  grid-column: 1/-1;
  grid-row: 13;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contenedor-teximg1-MAc {
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 500px;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}
.clase-imagen-none {
  height: 150px;
  width: 150px;
}
.text-post-img-mac {
  width: 340px;
  font-family: Quicksand;
  margin-left: 10px;
}
.text-post-img2 {
  width: 310px;
  font-family: Quicksand;
  margin-left: 10px;
}
.contenedor-titu-geo {
  font-size: 16px;
  font-weight: bold;
  margin-left: 70px;
  line-height: 1.9;
}
.texto-contenedor-monAc {
  font-size: 14px;
  line-height: 1.9;
  color: #494949;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-imagecard-MAc {
    grid-column: 1/-1;
    grid-row: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Quicksand;
    flex-direction: column;
  }
  .contendor-titulo-MAc {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 1.3;
  }
  .contenedor-sub-MAc {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    width: 90%;
    text-align: center;
  }
  .contenedor-imgprincipal-MAc {
    grid-column: 1/-1;
    grid-row: 2;
    background-image: url("../../assets/img/ImgSoluciones/MADimg1.jpeg");
    background-position: cover;
    background-repeat: no-repeat;
    background-size: 2000px;
    height: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: Quicksand;
  }
  .contenedor-tit-MAc {
    grid-column: 1/-1;
    grid-row: 4;
    height: auto;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Quicksand;
  }
  .contendor-texto-MAc {
    font-size: 14px;
    width: 91%;
    margin-top: 15px;
    line-height: 1.3;
  }
  .contendor-teximagenes-MAc {
    grid-column: 1/-1;
    grid-row: 13;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  .contenedor-teximg1-MAc {
    width: 90%;
    margin-right: 0px;
  }
  .clase-imagen-none {
    display: none;
  }
  .text-post-img-mac {
    width: 80%;
    margin-left: 0px;
  }
  .contenedor-titu-geo {
    font-size: 16px;
    font-weight: bold;
    margin-left: 0px;
    line-height: 1.9;
    text-align: center;
  }
  .texto-contenedor-monAc {
    font-size: 14px;
    line-height: 1.9;
    color: #494949;
    text-align: center;
  }
}
