.left-novedad-robos {
  margin-left: 200px;
}
/* .contenedor-img-robonov {
  width: 80%;
} */
.contenedorr-texto-novrobo2 {
  grid-column: 3/-1;
  grid-row: 4;
  height: auto;
  width: 100%;
  padding-top: 30px;
}
.contenedorr-texto-novrobo {
  font-family: Quicksand;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  width: 70%;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .left-novedad-robos {
    margin-left: 0px;
  }
  .contenedorr-texto-novrobo2 {
    grid-column: 2/-1;
    grid-row: 4;
    height: auto;
    width: 100%;
    padding-top: 30px;
  }
  .contenedorr-texto-novrobo {
    font-family: Quicksand;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .left-novcomponent {
    margin-left: 20px;
  }
}
